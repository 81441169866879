<template>
  <section id="referenzen">
    <feed>
      <slot />
    </feed>
  </section>
</template>

<script>
  export default {
    name: 'HomeReferenzen',

    components: {
      Feed: () => import('@/components/Feed'),
    },
  }
</script>
